$(document).ready(function() {

    $('.fac-detail__slider').owlCarousel({
        dots: true,
        items: 1,
        singleItem: true
    });

    $('.table').footable();

    $('.presseowl').owlCarousel({ //vorher ID: #owl__presse

        dots: true,
        responsive:{
            0:{
                items: 1
            },
            470:{
                items: 2
            },
            700:{
                items: 3
            }
        }
    });

    var $vOwl = $('#owl__veranstaltung');

    setTimeout(function(){
        $vOwl.owlCarousel({

            dots: false,
            responsive:{
                768:{
                    items: 3
                },
                992: {
                    items: 4
                }

            },

            margin: 15
        });
    },500);


    $(".v-prev").on('click', function() {
        $vOwl.trigger('prev.owl.carousel');
    });

    $(".v-next").on('click', function() {
        $vOwl.trigger('next.owl.carousel');
    });

    $('#owl__news').owlCarousel({
        items: 1,
        dots: true,
        singleItem: true
    });

    var $bOwl = $('#owl__buergerservice');

    $bOwl.on('initialized.owl.carousel', function (e) {
        if(e.item.count > e.page.size) {
            $('.b-prev, .b-next').css('display', 'block');
        }
        else if(e.item.count == e.page.size) {
            $('.b-prev, .b-next').css('display', 'none');
        }
    });

    $bOwl.owlCarousel({

        dots: false,
        responsive:{
            768:{
                items: 3
            },
            992: {
                items: 4
            }
        },
        margin: 15
    });

    $bOwl.on('refreshed.owl.carousel', function (e) {
        if(e.item.count > e.page.size) {
            $('.b-prev, .b-next').css('display', 'block');
        }
        else if(e.item.count == e.page.size) {
            $('.b-prev, .b-next').css('display', 'none');
        }
    });
    
    $(".b-prev").on('click', function() {
        $bOwl.trigger('prev.owl.carousel');
    });

    $(".b-next").on('click', function() {
        $bOwl.trigger('next.owl.carousel');
    });



    $(".navbar-open").on("click",function() {
        $(".grey-background").fadeToggle();
        $(".navigation-left").animate({
            left: '0'
        },250);
    });

    $('.js-nav-close').on('click', function () {
        $('.grey-background').fadeOut();
        $('.navigation-left').animate({
            left: '-260px'
        },250);
        //$('.search').slideUp();
    });

    $('.left-nav__dropdown').on('click', function () {
        $('.left-nav__dropdown.open').removeClass('open');
        $(this).addClass('open');
    });

    $('.left-nav__dropdown>a').on('click', function() {
        $(this).next('.left-nav').css('overflow-y', 'auto');
        $(this).next('.left-nav').css('display', 'block');
        $(this).next('.left-nav').animate({
            left: '0'
        }, 250);
        $(this).parent().parent('.left-nav').css('overflow-y', 'hidden');
        $(this).parents('.left-nav').scrollTop(0);
    });

    $('.left-nav__back').on('click', function() {
        $(this).parent('.left-nav').css('display', 'none');
        $(this).parent('.left-nav').animate({
            left: '-260px'
        }, 250);
        $(this).parent().parent().parent('.left-nav').css('overflow-y', 'auto');
    });

    var globalSearchOut = false;

    $('.global__search').on('click', function (e) {

        /*if($(e.target).hasClass('global__button')) {
            if(!globalSearchOut) {
                $('.global__button--submit').css('display', 'inline-block');
                $('.global__button--prov').css('display', 'none');
                globalSearchOut = true;
            }
        }*/

        $('.global input[name="searchterm"]').focus();

        //Globale Suche öffnen
        //$('.global__input').stop().animate({width: 'show'}, 250);

    });

    $(document).click(function(e) {
        /*if ($(e.target).parents('.global__search').length === 0) {
            $('.global__input').animate({width: 'hide'},250);
            $('.global__button--submit').css('display', 'none');
            $('.global__button--prov').css('display', 'inline-block');
            globalSearchOut = false;
        }*/
        //if($(e.target).parents('.nav-top__link--search').length === 0 ) {
        //    searchFocus = false;
        //    $('.search').animate({width: 'hide'},250);
        //    setTimeout(function () {
        //        $('.nav-top__link--search').find('a').removeClass('active');
        //        $('.nav-top__link--search').removeClass('sub');
        //    },250);
        //}
        if($(e.target).parents('.nav-top__link').length === 0) { //|| !$(e.target).parents('.nav-top__link--search').length === 0
            searchFocus = false;
            $('.search').animate({width: 'hide'},250);
            setTimeout(function () {
                $('.nav-top__link--search').find('a').removeClass('active');
                $('.nav-top__link--search').removeClass('sub');
            },250);
            $('.megadropdown').slideUp(250);
            if(window.innerWidth > 767) {
                $('.grey-background').fadeOut('fast');
            }
            $('.nav-top__link').removeClass('sub');
        }
    });

    var oldWidth = checkWidth(window.innerWidth);

    function checkWidth(i) {
        if(i < 768) {
            return 1;
        } else if (i < 992) {
            return 2;
        } else if (i > 991) {
            return 3;
        }
    }

    $(window).resize( function () {
        var newWidth = checkWidth(window.innerWidth);
        if(newWidth != oldWidth) {
            if(newWidth > 1) {
                $(".grey-background").fadeOut();
                $(".navigation-left").slideUp();
            }
            $('.search').slideUp();
            $('.megadropdown').css('display', 'none');
            searchFocus = false;
        }
    });

    var changeDropdown = null;
    var setDropdown = null;
    var hideDropdown = null;
    var lockMenu = true;
    var touch = false;
    var searchFocus = false;

    $('.nav-top__link').on('mouseenter', function () {
        if(!lockMenu && !touch) {

            clearTimeout(hideDropdown);

            $('.navigation-left').slideUp('fast');

            var $this = $(this);
            var $megadropdown = $this.find('.megadropdown');
            if($megadropdown.length > 0 ) {
                if( $('.nav-top__link.sub').length > 0) {
                    // altes dropdown schlie�en & neues anzeigen
                    if(changeDropdown != null) {
                        window.clearTimeout(changeDropdown);
                    }

                    changeDropdown = window.setTimeout(function () {
                        if($this.hasClass('nav-top__link--search') && !$('.nav-top__link.sub').hasClass('nav-top__link--search')) {
                            $('.megadropdown').stop().slideUp(250);
                            $('.grey-background').fadeOut('fast');
                            $megadropdown.animate({width: 'show'}, 250);
                            $this.find('a').addClass('active');
                        }
                        else if(!$this.hasClass('nav-top__link--search') && $('.nav-top__link.sub').hasClass('nav-top__link--search')) {
                            $('.megadropdown').stop().animate({width: 'hide'}, 250);
                            setTimeout(function () {
                                $('.nav-top__link').find('a').removeClass('active');
                            }, 250);
                            searchFocus = false;
                            $('.grey-background').fadeIn('fast');
                            $megadropdown.slideDown(250);
                        }
                        else {
                            $('.megadropdown').stop().hide();
                            $megadropdown.show();
                        }

                        $('.nav-top__link').removeClass('sub');
                        $this.addClass('sub');
                    }, 300);

                } else {
                    // dropdown reinsliden
                    if(setDropdown != null) {
                        window.clearTimeout(setDropdown);
                    }

                    setDropdown = window.setTimeout(function (){
                        clearTimeout(hideDropdown);
                        $this.addClass('sub');
                        if ($megadropdown.hasClass('search')) {
                            $megadropdown.animate({width: 'show'}, 250);
                            $this.find('a').addClass('active');
                        }
                        else {
                            $megadropdown.slideDown(250);
                            $('.grey-background').fadeIn('fast');
                        }

                    },200);
                }
            }
        }
    });


    $('.nav-top__link').on('click', function (e) {
        if(lockMenu || touch) {


            var $this = $(this);
            var $megadropdown = $this.find('.megadropdown');

            $('.navigation-left').slideUp('fast');

            if($megadropdown.length > 0 ) {
                if( $('.nav-top__link.sub').length > 0) {

                    //dropdown schließen

                    if ($this.hasClass('sub') && !$(e.target).parents().hasClass('megadropdown')) {
                        if($this.hasClass('nav-top__link--search')) {

                            $('.search form [name="searchterm"]').focus();

                            if(document.forms[1].searchterm.value!='' && searchFocus) {
                                document.forms[1].submit();
                            }
                            else if(document.forms[1].searchterm.value=='' && searchFocus) {
                                searchFocus = false;
                                $('.megadropdown').animate({width: 'hide'}, 250);
                                $('.nav-top__link').removeClass('sub');
                                setTimeout(function () {
                                    $(this).find('a').removeClass('active');
                                }, 250);
                            }
                            else {
                                searchFocus = true;
                            }
                        }
                        else {
                            $this.removeClass('sub');
                            $megadropdown.slideUp(250);
                        }
                        $('.grey-background').fadeOut('fast');
                    }
                    else {
                        // altes dropdown schlie�en & neues anzeigen

                        if(!$this.hasClass('nav-top__link--search') && $('.nav-top__link.sub').hasClass('nav-top__link--search')) {
                            $('.megadropdown').stop().animate({width: 'hide'}, 250);
                            setTimeout(function () {
                                $('.nav-top__link').find('a').removeClass('active');
                            }, 250);
                            searchFocus = false;
                            $('.grey-background').fadeIn('fast');
                            $megadropdown.slideDown(250);
                        }
                        else if($this.hasClass('nav-top__link--search') && !$('.nav-top__link.sub').hasClass('nav-top__link--search')) {
                            $('.megadropdown').stop().slideUp(250);
                            $('.grey-background').fadeOut('fast');
                            $megadropdown.animate({width: 'show'}, 250);
                            $this.find('a').addClass('active');
                            $('.search form [name="searchterm"]').focus();
                        }
                        else {
                            $('.megadropdown').stop().hide();
                            $megadropdown.show();
                        }

                        $('.nav-top__link').removeClass('sub');
                        $this.addClass('sub');
                    }

                } else {
                    // dropdown öffnen

                    $this.addClass('sub');
                    if ($megadropdown.hasClass('search')) {
                        $megadropdown.animate({width: 'show'}, 250);
                        $this.find('a').addClass('active');
                        $('.search form [name="searchterm"]').focus();
                        searchFocus = true;
                    }
                    else {
                        $megadropdown.slideDown(250);
                        searchFocus = false;
                        $('.grey-background').fadeIn('fast');
                    }

                }
            }
        }
    });

    /*$('.breadcrumb').find('a').on('click', function (e) {
        var titleAttr;
        titleAttr = $(this).attr('title');
        if (titleAttr == "Das Emsland") {
            var relTag = "das-emsland";
        }
        var $mainLink = $('.nav-top__link').find('a[rel="' + relTag + '"]');

        var $this = $mainLink;
        var $megadropdown = $this.find('.megadropdown');

        console.log($megadropdown);
    });*/

    $('.megadropdown').on('mouseenter', function() {
        clearTimeout(changeDropdown);
    });

    $('.nav-top__link--search').on('click', function(e) {
        if(!lockMenu && !touch) {
            var $this = $(this);
            var $megadropdown = $this.find('.megadropdown');

            $('.search form [name="searchterm"]').focus();

            if($(e.target).parents('.search__input').length === 0 ) {
                if($this.hasClass('sub')) {
                    if(document.forms[1].searchterm.value!='' && searchFocus) {
                        document.forms[1].submit();
                    }
                    else if(document.forms[1].searchterm.value=='' && searchFocus) {
                        searchFocus = false;

                        $('.megadropdown').animate({width: 'hide'}, 250);
                        $('.nav-top__link').removeClass('sub');
                        setTimeout(function () {
                            $(this).find('a').removeClass('active');
                        }, 250);

                    }
                    else {
                        searchFocus = true;
                    }
                }
                else if(!$this.hasClass('sub')) {
                    $this.addClass('sub');
                    $megadropdown.animate({width: 'show'}, 250);
                    $this.find('a').addClass('active');
                    searchFocus = true;
                }
            }
            else {
                searchFocus = true;
            }
        }
    });


    $('.nav-top__link').on('mouseleave', function () {
        var $this = $(this);
        if(!lockMenu && !touch) {
            clearTimeout(setDropdown);
            hideDropdown = window.setTimeout(function () {
                clearTimeout(changeDropdown);
                if ($this.hasClass('nav-top__link--search') && !searchFocus) {
                    if($('.megadropdown.search').css('display')==='block') {
                        $('.megadropdown').animate({width: 'hide'}, 250);
                        $('.nav-top__link').removeClass('sub');
                        setTimeout(function () {
                            $this.find('a').removeClass('active');
                        }, 250);
                    }
                    else {
                        $this.find('a').removeClass('active');
                    }


                }
                else if (!$this.hasClass('nav-top__link--search')) {
                    $('.megadropdown').slideUp(250);
                    $('.nav-top__link').removeClass('sub');
                }

                $('.grey-background').fadeOut('fast');

            }, 200);
        }
    });

    $(document).on('mousemove', function() {
        touch = Modernizr.touch;
        if(window.innerWidth > 767) {
            lockMenu = false;
        } else {
            lockMenu = true;
        }
    });

    $('.filter-select').on('change focusout', function() {
        var $selected = $('select option:not(".disabled"):selected, input.datepicker');

        console.log($selected);

        var $items = $('.filter__item');
        $items.show();
        if ($selected.length == 0) {
            return;
        }

        $selected.each(function() {
            var $selected = $(this);
            if($selected.val()) {
                $items.filter(function() {
                    if($selected.data('cat') == 'date') {
                        var start = $(this).data('start');
                        var end = $(this).data('end');
                        var startDate = moment(start, "DD.MM.YYYY").toDate();
                        var endDate = moment(end, "DD.MM.YYYY").toDate();
                        var searchDate = moment($selected.val(), "DD.MM.YYYY").toDate();
                        return !((startDate <= searchDate) && (searchDate <= endDate));
                    } else {
                        return $(this).data($selected.data('cat')) != $selected.data('val');
                    }
                }).hide();
            }
        });
    });

});

$('.datepicker').datetimepicker({
    useCurrent: false,
    locale: 'de',
    format: 'DD.MM.YYYY',
    extraFormats: [ 'YYYY-MM-DD', 'DD.MM.YYYY' ],
    icons: {
        time: 'fa fa-clock',
        date: 'fa fa-calendar',
        up: 'fa fa-caret-up',
        down: 'fa fa-caret-down',
        previous: 'fa fa-caret-left',
        next: 'fa fa-caret-right',
        today: 'fa fa-crosshairs',
        clear: 'fa fa-trash',
        close: 'fa fa-times'
    }
});

function checkForm(i) {
    if(document.forms[i].searchterm.value=='') {
        return(false);
    }
}

var topHead = $('#js-head')[0].offsetTop;

$(window).scroll(function() {
    var touch = Modernizr.touch;
    if (window.innerWidth > 767) {
        if($(this).scrollTop() > topHead ) {
            $('.contentsite .navbar__background--img, .contentsite .navbar-brand, .contentsite .nav-top__link, .contentsite .grey-background').addClass('smaller');
        }
        else {
            $('.navbar__background--img, .navbar-brand, .nav-top__link, .grey-background').removeClass('smaller');
        }
        // $('.grey-background').fadeOut('fast');
    }

    if(!$('.nav-top__link--search').hasClass('sub') || !touch) {
        $('.megadropdown.search').animate({width: 'hide'}, 250);
        setTimeout(function () {
            $('.nav-top__link--search').find('a').removeClass('active');
        }, 250);
        searchFocus = false;
        // $('.megadropdown').slideUp(250);
        // $('.nav-top__link').removeClass('sub');
    }

});


