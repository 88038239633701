var Breitband = {
    _$loader: null,

    init: function($loader) {
        this._$loader = $loader;

        $('.selectpicker').selectpicker({
            size: 4
        });
    },
    loadDistricts: function(city, $selectField, callback) {
        var me = this;
        this._request({
            'action': 'getDistricts',
            'city': city
        }, function(json) {
            me._updateSelectField(json.districts, $selectField);
            callback(json);
        });
    },
    loadStreets: function(city, district, $selectField, callback) {
        var me = this;
        this._request({
            'action': 'getStreets',
            'city': city,
            'district': district
        }, function(json) {
            me._updateSelectField(json.streets, $selectField);
            callback(json);
        });
    },
    loadHousenos: function(city, district, street, $selectField, callback) {
        var me = this;
        this._request({
            'action': 'getHousenos',
            'city': city,
            'district': district,
            'street': street
        }, function(json) {
            me._updateSelectField(json.housenos, $selectField);
            callback(json);
        });
    },
    loadResult: function(city, district, street, houseno, callback) {
        this._request({
            'action': 'getResult',
            'city': city,
            'district': district,
            'street': street,
            'houseno': houseno
        }, function(json) {
            callback(json);
        });
    },
    _request: function(data, callback) {
        var me = this;
        $.ajax({
            url: 'ajax.breitband.php',
            type: 'POST',
            data: data,
            dataType: 'json',
            beforeSend: function() {
                me._$loader.show();
            },
            success: function(result) {
                callback(result);
                me._$loader.hide();
            }
        });
    },
    _updateSelectField: function(data, $selectField) {
        $selectField.html('');

        for(var i = 0; i < data.length; i++) {
            $('<option>').val(data[i]).text(data[i]).appendTo($selectField);
        }

        $selectField.removeAttr('disabled');
        $selectField.selectpicker('refresh');

        if(data.length === 1) {
            $selectField.selectpicker('val', data[0]);
            $selectField.change();
        }
    }
};

var $breitbandOuter = $('#breitband--outer');
if($breitbandOuter.length > 0) {
    var $loader         = $breitbandOuter.find('#breitband--loader');
    var $result         = $breitbandOuter.find('#breitband--results');

    var $citySelect     = $breitbandOuter.find('#city');
    var $districtSelect = $breitbandOuter.find('#district');
    var $streetSelect   = $breitbandOuter.find('#street');
    var $housenoSelect  = $breitbandOuter.find('#houseno');

    Breitband.init($loader);

    $citySelect.change(function() {
        Breitband.loadDistricts($(this).find('option:selected').attr('value'), $districtSelect, function(json) {
            $streetSelect.html('').attr('disabled', 'disalbed').selectpicker('refresh');
            $housenoSelect.html('').attr('disabled', 'disalbed').selectpicker('refresh');
            hideResults();
        });
    });

    $districtSelect.change(function() {
        Breitband.loadStreets($citySelect.find('option:selected').attr('value'), $(this).find('option:selected').attr('value'), $streetSelect, function(json) {
            $housenoSelect.html('').attr('disabled', 'disalbed').selectpicker('refresh');
            hideResults();
        });
    });

    $streetSelect.change(function() {
        Breitband.loadHousenos($citySelect.find('option:selected').attr('value'), $districtSelect.find('option:selected').attr('value'), $(this).find('option:selected').attr('value'), $housenoSelect, function(json) { /** callback */ });
        hideResults();
    });

    $housenoSelect.change(function() {
        Breitband.loadResult($citySelect.find('option:selected').attr('value'), $districtSelect.find('option:selected').attr('value'), $streetSelect.find('option:selected').attr('value'), $(this).find('option:selected').attr('value'), function(json) {
            hideResults();

            if(json.status >= 1 && json.status <= 3) {
                $result.find('[data-status="' + json.status + '"]').show();
                $result.find('[data-status="' + json.status + '"]').find('.breitband--result-provider').html(json.provider);
                $result.find('[data-status="' + json.status + '"]').find('.breitband--result-max_download').html(json.max_download);
            }
        });
    });

    /**
     *
     */
    function hideResults() {
        $('.breitband--result-provider').html('');
        $('.breitband--result-max_download').html('');
        $('.breitband--result-outer').hide();
    }
}